.container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    font-family: sans-serif;
    color: #ffe8cc;
}

body {
    /*background-color: #F1F3F5;*/
    background-image: url("/public/images/bg-white-1.png");

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    touch-action: none;

}
html{
    background-image: url("/public/images/bg-white-1.png");

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

}

