/* Основний стиль для шапки */
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    z-index: 100;
}

/* Логотип */
.logo {
    font-size: 2.4rem;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

/* Десктопна навігація */
.nav {
    display: flex;
    gap: 20px;
}

.navButton {
    background: none;
    font-size: 1.6rem;
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    color: black;
    transition: color 0.3s ease;
}

.navButton:hover {
    color: #555555;
}

/* Бургер-іконка */
.burgerIcon {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.burgerIcon div {
    width: 100%;
    height: 4px;
    background-color: black;
    transition: all 0.3s ease;
}

/* Бургер-меню */
.burgerMenu {
    display: none;
    position: fixed;
    top: 0;
    left: -100%; /* Початково приховано */
    width: 100%;
    height: 100%;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: left 0.3s ease;
    z-index: 200;
}

.burgerMenu.open {
    left: 0; /* Відкрити меню */
}

/* Кнопка закриття бургер-меню */
.closeButton {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
}

/* Пункти бургер-меню */
.menuItem {
    font-size: 1.8rem;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    background: none;
    color: black;
    cursor: pointer;
}

.menuItem:hover {
    color: #555;
}

/* Медіа-запити */
@media (max-width: 768px) {
    .nav {
        display: none; /* Сховати десктопну навігацію на мобільних */
    }

    .burgerIcon {
        display: flex; /* Показати бургер-іконку на мобільних */
    }

    .burgerMenu {
        display: flex; /* Показати меню на мобільних */
    }
}
