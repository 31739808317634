.aboutSection {

    margin-bottom: 60px;

    color: #333;
    line-height: 1.6;
}
h1{
    font-size: 36px;
    font-weight: 700;
}
.aboutSection h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}
.aboutSection p{
    text-align: left;
    font-size: 30px;
    line-height: 1.3;
    font-weight: 500;
}

.redText{
    color: #fff4e6;
    font-style: italic;
}
@media screen and (max-width: 1400px) {
    .aboutSection h1 {
        font-size: 32px;
    }
    .aboutSection p {
        font-size: 24px;
    }
}

/*
  LG: до 1200px
  Для стандартних десктопів/лаптопів
*/

@media screen and (max-width: 1200px) {
    .aboutSection h1 {
        font-size: 28px;
    }
    .aboutSection p {
        font-size: 22px;
    }
}

/*
  MD: до 992px
  Для планшетів у горизонтальній орієнтації та невеликих ноутбуків
*/

@media screen and (max-width: 992px) {
    .aboutSection h1 {
        font-size: 26px;
    }
    .aboutSection p {
        font-size: 20px;
    }
}

/*
  SM: до 768px
  Для планшетів у вертикальній орієнтації
*/

@media screen and (max-width: 768px) {
    .aboutSection h1 {
        font-size: 24px;
    }
    .aboutSection p {
        font-size: 18px;
    }
}

/*
  XS: до 576px
  Для мобільних телефонів
*/

@media screen and (max-width: 576px) {
    .aboutSection h1 {
        font-size: 20px;
    }
    .aboutSection p {
        font-size: 16px;
    }
}

@media screen and (max-width: 1200px) and (min-resolution: 120dpi) {
    .aboutSection p {
        /* Трохи зменшуємо розмір тексту,
           якщо екран менший за 1200px І має щонайменше 120dpi (приблизно 125% масштаб) */
        font-size: 20px;
    }
}