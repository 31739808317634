

.topic{
    font-family: 'Inria Sans', bold, serif;

    font-weight: bold;
}
.topich1{
    font-size: 32px;
    font-weight: 700;
    color: #444;
    margin-bottom: 20px;
    text-transform: uppercase;
    display: inline-block;
    padding-bottom: 5px;
}
table {
    border: 0;
    padding: 25px;
    border-spacing: 20px;
}

td {
    color: #FFF4E6;
    border: 0;
    padding: 15px;
    vertical-align: top;
    font-size: 28px;
    border-radius: 8px;
}
td {
    border-right: 1px solid #FFF4E6;
    /*border-bottom: 2px solid #FFF4E6;*/
}


.icontext {
    display: flex;
    align-items: flex-start;
}



.numerate{
    font-size: 56px;
    position: relative;
    top: -65px;
    left: -10px;
    color: #FFF4E6;
    font-weight: 300;
}

@media (max-width: 1024px) {
    .table {
        border-spacing: 10px;

    }

    td {
        font-size: 24px;
        padding: 10px;
    }

    .icons {
        width: 36px;
        margin-right: 15px;
    }
}
@media (max-width: 845px) {
    .table {
        border-spacing: 10px;

    }

    td {
        font-size: 22px;
        padding: 10px;
    }

    .icons {
        width: 36px;
        margin-right: 15px;
    }
}
@media (max-width: 795px) {
    .table {
        border-spacing: 10px;

    }

    td {
        font-size: 22px;
        padding: 9px;
    }

    .icons {
        width: 36px;
        margin-right: 15px;
    }
}
@media (max-width: 775px) {
    .table {
        border-spacing: 10px;

    }

    td {
        font-size: 22px;
        padding: 5px;
    }

    .icons {
        width: 36px;
        margin-right: 15px;
    }
}
/* Для мобільних пристроїв */
@media (max-width: 768px) {

    .mainediv{
        position: relative;
        top: 10%;
    }
    table {
        display: block;
        padding: 0;
        border-spacing: 0;

    }

    tr {
        display: block;
        margin-bottom: 10px;


    }
    .numerate{
        height: 20px;
        font-size: 36px;
        position: relative;
        top: -15px;
        left: -5px;
        color: #FFF4E6;
        font-weight: 300;
    }

    td {
        display: block;
        width: 100%;

        border-radius: 0;
        border-right: 0;
        border-bottom: 2px solid #eee;
        box-sizing: border-box;
    }

    .icontext {
        flex-direction: row;
        align-items: center;
        font-size: 16px;
    }

    .icons {
        width: 36px;
        margin-right: 10px;
    }
}
@media (max-height: 750px) {


    .topich1 {
        font-size: 20px;
        text-align: center;
    }

    td {position: relative;
        border-bottom: 1px solid #FFF4E6;
        top: -20px;
        font-size: 18px;
        padding: 10px 5px;
    }
    .numerate{
        height: 2px;
        font-size: 24px;
        position: relative;
        top: -40px;
        left: -5px;
        color: #FFF4E6;
        font-weight: 300;
    }
    .icontext {

        font-size: 14px;
    }
}
/* Для нестандартних екранів */
@media (max-height: 680px) {


    .topich1 {
        font-size: 20px;
        text-align: center;
    }

    td {position: relative;
        border-bottom: 1px solid #FFF4E6;
        top: -20px;
        font-size: 18px;
        padding: 10px 5px;
    }
    .numerate{
        height: 2px;
        font-size: 24px;
        position: relative;
        top: -40px;
        left: -5px;
        color: #FFF4E6;
        font-weight: 300;
    }
    .icontext {

        font-size: 14px;
    }
}

