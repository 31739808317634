/* Контейнер відгуків */
.reviewContainer {
    margin-left: 50px;
    text-align: justify;
    float: right;
    width: 400px;
    padding: 20px;
    margin-right: 30px;
    position: relative;
    overflow: hidden; /* Обрізає все, що виходить за межі контейнера */
    height: 270px;
    /*box-shadow: #d5d5d5 9px 11px 22px 0px;*/
    border-radius: 8px; /* Згладжені краї */
    background-color: rgba(217, 217, 217, 0.40);

}

/* Відгук */
.reviewContainer .review {
    position: relative;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

/* Анімація входу */
.slideIn {
    opacity: 1;
    transform: translateX(0);
}

/* Анімація виходу */
.slideOut {
    opacity: 0;
    transform: translateX(-100%);
}

/* Текстові стилі */
.review h4 {
    position: relative;
    left: -10px;
    top: -20px;
    margin: 0;
    font-size: 1.6rem;
    color: #333;
}
.reviewimagecontainer{
    float: left;
    background-color: #333333;
    padding: 10px;

    border-radius: 8px 0 0 0;
    position: relative;
    top: -20px;
    left: -20px;
    width: 49px;
    height: 49px;

.reviewimage{
    width: 50px;
}
}
.review .profession {
    position: relative;
    top: -25px;
    left: -10px;
    font-size: 1.1rem;
    color: #777;
    margin-top: 0;
    margin-bottom: 10px;
}

.review .content {
    position: relative;
    top: -20px;

    font-size: 1.2rem;
    margin: 10px 0;
    line-height: 1.4;
}

.review .rating {
    position: relative;
    top: -20px;
    font-size: 1.5rem;
    color: #f4c542;
    margin: 10px 0;
}

.review .createdAt {
    font-size: 0.9rem;
    color: #555;
}

/* Зірки */
.star {
    display: inline-block;
    font-size: 1.5rem;
    color: #ccc;
    margin-right: 2px;
}

.star.filled {
    color: #000000;

}
@media screen and (max-width: 940px) and (orientation: landscape) {
    .reviewContainer{
        display: none;
    }
}
@media screen and (max-width: 850px) and (orientation: landscape) {
    .reviewContainer{
        display: none;
    }
}
@media (max-width: 768px) {

.reviewContainer{
    display: none;
    position: relative;
    top: 250px;
    left: 50px;
    float: none;
}
}