@import url('https://fonts.googleapis.com/css2?family=Inria+Sans&family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');*{
    font-family: 'Outfit', bold , sans-serif;
}

.full-screen-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease, transform 0.8s ease;
    opacity: 0;
    transform: translateY(100%);
    z-index: 0;

}

.full-screen-section.active {
    opacity: 1;
    transform: translateY(0);
    z-index: 1;

}

.full-screen-section.inactive {
    opacity: 0;
    transform: translateY(100%);
    z-index: 0;
}

.full-screen-container {
    position: relative;
    width: 100%;
    height: 94vh;
    overflow: hidden;

}
