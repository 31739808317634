.maintext {
    font-size: 128px;
    line-height: 1;
    font-weight: 900;
    text-transform: uppercase;
    padding-right: 0px;
    text-align: left;
}

.description {
    font-weight: 500;
    font-size: 48px;
    margin-bottom: 90px;
}

.email {
    height: 50px;
    width: 50px;
}
@media screen and (min-resolution: 105.6dpi),
screen and (min-resolution: 1.1dppx),
screen and (-webkit-min-device-pixel-ratio: 1.1)
and (min-width: 1025px) {
    .maintext {
        font-size: 90px;
    }
    .description {
        font-size: 32px;
    }
}


/* Адаптивні стилі для різних ширин екрана */
@media screen and (max-width: 1920px) {
    .maintext {
        font-size: 96px;
        text-align: left;
    }
    .description {
        font-size: 36px;
        margin-bottom: 60px;
        margin-right: 250px;
    }
}

@media screen and (max-width: 768px) {
    .maintext {
        font-size: 84px;
        text-align: left;
    }
    .description {
        font-size: 42px;
        margin-bottom: 40px;
        margin-right: 10px;
    }
    .email {
        height: 40px;
        width: 40px;
    }
}

@media screen and (max-width: 600px) {
    .maintext {
        font-size: 54px;
        text-align: left;
    }
    .description {
        font-size: 34px;
        margin-bottom: 40px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 480px) {
    .maintext {
        font-size: 58px; /* Так, інколи 480px < 600px, але залишимо так, якщо це ваш задум */
        text-align: left;
    }
    .description {
        font-size: 28px;
        margin-bottom: 30px;
    }
    .email {
        height: 30px;
        width: 30px;
    }
}

/* Адаптація по висоті */
@media screen and (max-height: 900px) {
    .maintext {
        font-size: 46px;
        text-align: left;
    }
    .description {
        font-size: 24px;
        margin-bottom: 30px;
    }
}

@media screen and (max-height: 800px) {
    .maintext {
        font-size: 32px;
        text-align: left;
    }
    .description {
        font-size: 24px;
        margin-bottom: 30px;
    }
}


