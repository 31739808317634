.container {
    margin: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background: white;
}

.table th, .table td {
    text-align: left;
    padding: 10px;
    border: 1px solid #ddd;
}

.table th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.deleteButton {
    color: white;
    background-color: #d9534f;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
}

.deleteButton:hover {
    background-color: #c9302c;
}

.error {
    color: #d9534f;
    font-size: 0.9rem;
    margin-top: 10px;
}
