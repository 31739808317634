.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: sans-serif;
    color: #333;
}

h2, h3 {
    margin-bottom: 20px;
}

.adminList {
    list-style: none;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 30px;
}

.adminList li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.adminList li:last-child {
    border-bottom: none;
}

.loginForm, .adminForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}

.loginForm label,
.adminForm label {
    font-weight: bold;
}

.loginForm input,
.adminForm input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitButton,
.logoutButton {
    margin-top: 10px;
    padding: 10px;
    background: #2196F3;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton:hover,
.logoutButton:hover {
    background: #1976D2;
}

.ordersSection {
    margin-top: 40px;
}
