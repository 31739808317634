.Lets{
    position: absolute; /* Встановлює позицію відносно найближчого відносного контейнера */
    top: 40px; /* Розташовує у верхньому краї */
    left: 0px; /* Розташовує у лівому краї */

    text-align: left; /* Вирівнює текст по лівому краю */
}

.formSection {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    font-family: 'Arial', sans-serif;
}

.formSection h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;

}

.formSection p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
    text-align: left;
}

.form {
    position: absolute;

    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    width: 100%;
    max-width: 800px;

}

.form label {
    display: block;
    font-size: 0.9rem;
    font-weight: bold;
    color: #555;
    margin-bottom: 0.5rem;
}

.form input,
.form textarea {
    font-size: 0.9rem;
    border: none;
    border-bottom: 1px solid #717171;
    margin-bottom: 1rem;
    background: none;
    outline: none;
    transition: border-color 0.3s;
    width: 100%;

}
.permis{
    color: white;
}

.form textarea:last-of-type{
    border: 1px solid #717171;
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
}
.fullWidthField {
    grid-column: span 2; /* Розтягує поле на 2 колонки */
}


.form input:focus,
.form textarea:focus {
    border-color: #ffffff;
}

.form textarea {
    resize: none;
    height: 100px;
    grid-column: span 2;

}
.submitButton img{

    width: 18px;
    float: right;
}
.submitButton {

    text-align: left;
    grid-column: span 2;
    width: 160px;
    font-size: 1rem;
    padding: 10px 10px;
    font-weight: bold;
    color: #000000;
    background: none;
    border: 1px solid #ffffff;
    cursor: pointer;
    transition: background 0.3s;
}


.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1rem;

    background: none;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
    .formSection {
        position: relative;
        top: -80px;
    }

    .formSection h1 {
        font-size: 1.2rem;
        display: none;
    }

    .formSection p {
        display: none;
        font-size: 0.8rem;
    }

    .form {
        top: 130px;
        width: 95%;
    }

    .closeButton {
        position: absolute;
        top: 110px;
    }

    .form label{
        padding-bottom: 0;
        margin-bottom: 0 ;
    }
    .form textarea:last-of-type{
        position: relative;
        width: 50%;
        height: 50px;
    }
    .submitButton{
        position: relative;
        top: -100px;
        left: 67%;
    }
}


@media screen and (max-width: 768px) and (orientation: landscape) {
    .formSection {
        position: relative;
        top: -80px;
    }

    .formSection h1 {
        font-size: 1.2rem;
        display: none;
    }

    .formSection p {
        display: none;
        font-size: 0.8rem;
    }

    .form {
        top: 130px;
        width: 95%;
    }

    .closeButton {
        position: absolute;
        top: 110px;
    }

    .form label{
        display: none;
    }
    .form textarea:last-of-type{
        position: relative;
        width: 50%;
    }
    .submitButton{
        position: relative;
        top: -125px;
        left: 67%;
    }
}


@media (max-width: 468px) {

    .formSection h1{
        font-size: 1.2rem;
    }
    .formSection p{
        font-size: 0.8rem;
    }
    .form{
        top: 130px;
        width: 95%;
    }
}

