/* Контейнер загальної секції */
.ourworkcontainer {
    text-align: left;
    padding: 40px;
    font-family: 'Roboto', sans-serif;

    color: #333;
    width: 100%; /* Фіксована ширина секції */
    box-sizing: border-box;

}

/* Заголовок секції */
.ourworktitle {
    font-size: 32px;
    font-weight: 700;
    color: #444;
    margin-bottom: 20px;
    text-transform: uppercase;
    display: inline-block;
    padding-bottom: 5px;
}

/* Контейнер для колонок */
.ourworkcolumns {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: 1000px; /* Фіксована максимальна ширина */
    margin: 0 0px;
    height: 400px; /* Фіксована висота секції */

}

/* Ліва колонка (список проектів) */
.projectlist {
    flex: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    height: 100%; /* Займає всю висоту секції */
    overflow-y: auto; /* Смуга прокрутки для довгих списків */
    box-sizing: border-box;

    padding-right: 10px;
}

.projectitem {
    border-bottom: 1px solid #000000;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #555;
    cursor: pointer;
    padding: 10px 10px;
    border-left: 4px solid transparent;
    transition: color 0.3s ease, border-color 0.3s ease;
}

.projectitem.active {
    font-weight: 700;
    color:  #676767;
    border-bottom: 1px solid #676767;
}

.projectlink {
    text-decoration: none;
    color: inherit;
    display: block;
    white-space: nowrap; /* Забороняє переноси тексту */
    overflow: hidden; /* Сховати текст, якщо він занадто довгий */
    text-overflow: ellipsis; /* Додає "..." для довгих текстів */
}

/* Права колонка (зображення проекту) */
.imagecontainer {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px; /* Фіксована ширина для зображення */
    height: 100%; /* Займає всю висоту секції */
    box-sizing: border-box;
}

.projectimage {
    width: 100%; /* Ширина відповідно до контейнера */
    height: 100%; /* Займає всю висоту контейнера */
    object-fit: cover;
    border-radius: 8px;
    box-sizing: border-box;
}
.projectlink img{
    float: right;
    width: 18px;

}


@media screen and (max-width: 768px) {
    .imagecontainer{
        display: none;
    }

}

@media screen and (max-width: 400px) {
    .imagecontainer{
        display: none;
    }

}
@media screen and (max-width: 1000px) and (orientation: landscape) {
    .imagecontainer{
        width: 70%;
        height: 70%;
    }
    .projectitem{
        font-size: 14px;
    }
    .ourworktitle{
        display: none;
    }
    .ourworkcontainer{
        position: relative;
        top: 10%;
    }
}
